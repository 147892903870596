export default [
    {
    title: 'Dashboards',
    icon: 'HomeIcon',
    tagVariant: 'light-warning',
    route: 'dashboard-ecommerce',
    },
    {
        title: 'PPP',
        route: 'isp-username-list',
        icon: 'WifiIcon',
    },
    {
        title: 'Invoice',
        route: 'isp-invoice-list',
        icon: 'FileTextIcon',
    },
    {
        title: 'Router Setting',
        route: 'isp-router-list',
        icon: 'ZapIcon',
    },
    {
        title: 'Package Setting',
        route: 'isp-package-list',
        icon: 'BookOpenIcon',
    },
    {
        title: 'Mobile Banking',
        route: 'mobile-banking-payment-setting',
        icon: 'CreditCardIcon',
    },
    {
        title: 'Settlement',
        icon: 'FileTextIcon',
        children: [
            {
            title: 'New Settlement',
            route: 'admin-isp-settlement-list',
            },
            {
            title: 'Settlement List',
            route: 'admin-settlement-list',
            }
        ],
    },
    {
        title: 'ISP',
        icon: 'AlignJustifyIcon',
        children: [
            {
            title: 'ISP List',
            route: 'admin-isp-list',
            },
            {
            title: 'ISP PPP',
            route: 'admin-isp-ppp-list',
            },
            {
            title: 'Unassign PPP',
            route: 'admin-isp-unassign-ppp-list',
            }
        ],
    },
    {
        title: 'User Manage',
        icon: 'UsersIcon',
        children: [
            {
                title: 'Users',
                route: 'admin-user-list',
            },
            {
                title: 'Role',
                route: 'admin-role-list',
            },
            {
                title: 'Permission',
                route: 'admin-permission-list',
            }
        ],
    },
    {
        title: 'Payment History',
        route: 'service-payment-history',
        icon: 'CreditCardIcon',
    },
    {
        title: 'Service Package',
        route: 'admin-isp-service-package-list',
        icon: 'LifeBuoyIcon',
    },
    {
        title: 'Coupon Code',
        route: 'admin-coupon-code-list',
        icon: 'GiftIcon',
    }
]
