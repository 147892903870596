<template>
    <div class="navbar-container d-flex content align-items-center">

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link
                    class="nav-link"
                    @click="toggleVerticalMenuActive"
                >
                    <feather-icon
                        icon="MenuIcon"
                        size="21"
                    />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex" >
            <!-- Bookmarks Container -->
            <bookmarks />
            <div v-if="userData">
            <h4 class="text-warning text_shadows text-center" v-if="userData.roles[0].name == 'ISP Admin' && ! bill_payment_status"
                  @click="$router.push({ name: 'service-charge-package-list' })">
                Your MikroBilling Subscription has been Expired, Please Pay Renew Now
            </h4>
            </div>
        </div>

        <b-navbar-nav class="nav align-items-center ml-auto" v-if="userData">

          <span class=" mr-2" v-if="userData.roles[0].name == 'Master Admin'">
                 <span class="text-success">  {{ baseUrl }}</span>
                 <p>New ISP For Copy Link & Open New Browser</p>
           </span>
            <div class="text-center" v-if="userData.roles[0].name == 'Master Admin'">
                <select class="form-control" id="ispListId">
                    <option value="">Please select one</option>
                    <option v-for="item in isp_list" :value="item.value">{{ item.label }}</option>
                </select>
            </div>
            <dark-Toggler class="d-none d-lg-block"/>

            <user-dropdown/>
        </b-navbar-nav>
    </div>
</template>

<script>
import {ISP_LIST, SERVICES_PAYMENT_STATUS} from "@core/services/api";
import {
    BLink, BNavbarNav,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'
import vSelect from "vue-select";
import store from '@/store'

export default {
    components: {
        BLink,
        // Navbar Components
        BNavbarNav,
        Bookmarks,
        DarkToggler,
        UserDropdown,
        vSelect
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {
            },
        },
    },
    data() {
        return {
            baseUrl: window.location.origin + '/register',
            userData: JSON.parse(localStorage.getItem('userData')),
            isp_list: [
                {label: "Select ISP", value: null}
            ],
            isp_id: null,
            bill_payment_status: ''
        }
    },
    methods: {
        idpListData() {
            this.$http
                .get(process.env.VUE_APP_BASEURL + ISP_LIST)
                .then((res) => {
                    this.isp_list = res.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        userData.value = undefined;
                    }
                });
        },
        setSelected(value) {
            console.log(value);
            store.state.appConfig.isp_id = value;
        },
        billPayStatusCheck() {
            if (this.userData){
                this.$http
                    .get(process.env.VUE_APP_BASEURL + SERVICES_PAYMENT_STATUS)
                    .then((res) => {
                        this.bill_payment_status = res.data.data.bill_payment_status;
                    });
            }
        },
    },
    mounted() {
        this.idpListData();
        if (this.userData && this.userData.roles[0].name == 'ISP Admin'){
            this.billPayStatusCheck();
        }
    },
}
</script>
<style>
</style>
